export const data = [
  {
    image: 'img/boldare-awards/logo-awwwards-16.png',
    link:
      'https://www.boldare.com/blog/what-our-designers-learned-from-awwwards/',
  },
  {
    image: 'img/boldare-awards/logo-awwwards-mobile.png',
    link: 'https://boards.boldare.com/',
  },
  {
    image: 'img/boldare-awards/logo-gda.png',
    link: 'https://www.boldare.com/blog/we-won-german-design-awards-2021/',
  },
  {
    image: 'img/boldare-awards/logo-lovie.png',
    link: 'https://www.boldare.com/blog/we-won-gold-in-the-lovie-awards/',
  },
  {
    image: 'img/boldare-awards/logo-css-winner.png',
  },
  {
    image: 'img/boldare-awards/logo-indigo.png',
    link: 'https://www.boldare.com/blog/silver-indigo-award-prize-for-boldare/',
  },
  {
    image: 'img/boldare-awards/logo-webby-award.png',
    link: 'https://www.boldare.com/blog/2021-webby-honoree-award-for-boldare/',
  },
  {
    image: 'img/boldare-awards/logo-design-award.png',
    link: 'https://www.boldare.com/blog/new-design-awards-for-boldare/  ',
  },
  {
    image: 'img/boldare-awards/nextgen-award.png',
    link: 'https://www.boldare.com/blog/boldare-won-nextgen-enterprise-award/',
  },
];
