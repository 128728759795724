import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getArticleSlug, getCloudinaryUrl } from 'config/helpers';
import {
  DateComponent,
  Heading,
  Paragraph,
  Box,
  YouTubePlayer,
  PopUp,
  FakeVideoPlayer,
  IconSVG,
} from 'components';
import { classNames, getYoutubeThumbnailUrl } from 'utils';
import './BoxArticle.scss';

const ArticleContent = ({ title, description, date, url, id, isExternal }) => (
  <>
    <div className={'box-article__top'}>
      <Heading
        type={3}
        size={'medium-plus-small'}
        additionalClass={'box-article__title'}
      >
        {title}
      </Heading>
      <div className={'box-article__lead'}>
        <Paragraph
          size={'default'}
          color={'black'}
          additionalClass={'text--light'}
        >
          {description}
        </Paragraph>
      </div>
    </div>
    <div className={'box-article__bottom'}>
      <div className={'box-article__info'}>
        <Paragraph
          size={'small'}
          color={'black'}
          additionalClass={
            'box-article__category box-article__category--single'
          }
        >
          {date && <DateComponent date={date} />}
          <IconSVG
            name={'arrow-blog'}
            color={'black'}
            additionalClass={'box__badge--arrow'}
          />
        </Paragraph>
      </div>
    </div>
  </>
);

const YouTubeContent = ({
  settings,
  title,
  description,
  id,
  isMobile,
  videoPopupVisible,
  setVidePopupVisible,
}) => (
  <>
    <div className={'box-article__header box-article__main-content'}>
      <span className={'box__badge box__badge--article text--bold'}>
        Video
      </span>
      {isMobile ? (
        <YouTubePlayer src={settings?.url} light />
      ) : (
        <FakeVideoPlayer
          title={title}
          handleClick={() => setVidePopupVisible(true)}
        >
          <img
            src={getYoutubeThumbnailUrl(settings?.url)}
            alt={title}
            className={'image box-article__cover-image'}
            loading={'lazy'}
          />
        </FakeVideoPlayer>
      )}
    </div>
    <div
      className={classNames('box-article__content', 'box__content--static', {
        'box--short': title?.length <= 29,
        'box--long': title?.length > 29,
      })}
    >
      <ArticleContent
        title={title}
        description={description}
        date={settings?.date}
        url={settings?.url}
        id={id}
        isExternal
      />
    </div>
    {!isMobile && (
      <PopUp
        visible={videoPopupVisible}
        visibilityHandler={showPoup => setVidePopupVisible(showPoup)}
        additionalClass={'box-article__popup'}
      >
        <YouTubePlayer src={settings.url} playing />
      </PopUp>
    )}
  </>
);

const ExternalContent = ({
  settings,
  title,
  description,
  coverImage,
  id,
  service,
}) => (
  <>
    <div className={'box-article__header box-article__main-content'}>
      <span className={'box__badge box__badge--article text--bold'}>
        {service || 'Article'}
      </span>
      <a
        href={settings.url}
        target={'_blank'}
        id={`article-img-${id}`}
        rel={'noopener noreferrer nofollow'}
      >
        <img
          src={getCloudinaryUrl(coverImage, 'f_auto,q_auto,h_320')}
          alt={title}
          className={'image box-article__cover-image'}
          loading={'lazy'}
        />
      </a>
    </div>
    <div
      className={classNames('box-article__content', {
        'box--short': title?.length <= 29,
        'box--long': title?.length > 29,
      })}
    >
      <ArticleContent
        title={title}
        description={description}
        url={settings.url}
        id={id}
        isExternal
      />
    </div>
  </>
);

const DefaultContent = ({
  settings,
  title,
  description,
  coverImage,
  id,
  post,
}) => (
  <>
    <div className={'box-article__header box-article__main-content'}>
      <span
        className={classNames('box__badge text--bold', {
          'box__badge--case-study': settings?.category === 'Case Study',
          'box__badge--article': settings?.category !== 'Case Study',
        })}
      >
        {settings?.category === 'Case Study'
          ? 'Case Study'
          : settings?.category === 'Video'
          ? 'Video'
          : 'Article'}
      </span>

      <img
        src={getCloudinaryUrl(coverImage, 'f_auto,q_auto,h_320')}
        alt={title}
        className={'image box-article__cover-image'}
        loading={'lazy'}
      />
    </div>
    <div
      className={classNames('box-article__content', {
        'box--short': title?.length <= 29,
        'box--long': title?.length > 29,
      })}
    >
      <ArticleContent
        title={title}
        description={description}
        date={settings?.category !== 'Case Study' ? settings?.date : null}
        url={getArticleSlug(post)}
        id={id}
      />
    </div>
  </>
);

const BoxArticle = ({ post, id, campaignID }) => {
  const [videoPopupVisible, setVidePopupVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 480);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { frontmatter } = post.node;
  const { title, box, templateKey, settings, service } = frontmatter;

  if (!box || !settings) {
    return null;
  }

  const url = settings?.url || getArticleSlug(post);
  const coverImage =
    box?.content.coverImage || frontmatter.cover || 'img/human-eye.jpg';
  const description = box?.content.tileDescription;
  const isExternal = templateKey === 'external-page';
  const renderContent = () => {
    switch (templateKey) {
      case 'youtube-video':
        return (
          <YouTubeContent
            settings={settings}
            title={title}
            description={description}
            id={id}
            isMobile={isMobile}
            videoPopupVisible={videoPopupVisible}
            setVidePopupVisible={setVidePopupVisible}
          />
        );
      case 'medium-page':
      case 'external-page':
        return (
          <ExternalContent
            settings={settings}
            title={title}
            description={description}
            coverImage={coverImage}
            id={id}
            service={service}
          />
        );
      default:
        return (
          <DefaultContent
            settings={settings}
            title={title}
            description={description}
            coverImage={coverImage}
            id={id}
            post={post}
          />
        );
    }
  };
  
  return (
    <Box
      additionalClass={classNames(
        {
          'box-case-study': settings?.category === 'Case Study',
          'box-article': settings?.category !== 'Case Study',
        },
        campaignID,
      )}
    >
      <a
        href={url}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noopener noreferrer nofollow' : undefined}
        className={'box-article__link'}
      >
        {renderContent()}
      </a>
    </Box>
  );
};

BoxArticle.propTypes = {
  post: PropTypes.shape({
    node: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        box: PropTypes.object,
        templateKey: PropTypes.string,
        settings: PropTypes.object,
        service: PropTypes.string,
        cover: PropTypes.string,
      }),
    }),
  }).isRequired,
  id: PropTypes.string.isRequired,
  campaignID: PropTypes.string,
};

export default BoxArticle;
